<div class="container-lg col-md-10">
  <div class="jumbotron">
   <mat-card>


          <mat-horizontal-stepper labelPosition="bottom">

            <mat-step *ngFor="let step of listTheme | slice:0:4; let index = index; let last = last;">

              <ng-template matStepLabel >
                <span class="text-wrap">{{ListByThemeName[index].mtee_question_theme_display_name[selectedLanguage]}}
                </span>
              </ng-template>

              <div>
               <p class="mb-0 font-weight-bold ThemeInstruction"> {{ListByThemeName[index].mtee_question_theme_instruction[selectedLanguage]}} </p> 
              </div>

              <div class="progress">
                <mat-progress-bar mode="determinate" value={{progressBarValue}}>{{progress}}</mat-progress-bar>
              </div>

              <!-- *ngIf="ListByThemeName[index].mtee_question_theme_name != 'theme6'" ---- Questions on Page: {{step.length}}  | Total Answered {{progress}}  | Previously Answered: {{totalAnswered}} | Total Questions: {{totalQuestions}} | Page Question :{{pageQuestionLength}}-->
              <mat-card-content *ngFor="let question of step; index as i" >

                <mat-card >

                  <p class="bigger-font QuestionText">{{i+1}}.  {{question.mtee_question_text[selectedLanguage]}}</p>


                  <div *ngIf="question.questionType == 'Radio'">
                                    <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group" >
                                             <mat-radio-button mat-button  type="button" class="example-radio-button"
                                                               *ngFor="let option of question.options ;index as j;"
                                                               [value]="question.options[j].mtee_question_option_value"
                                                              (click)="answeredQuestion($event, question.mtee_question_id, question.options[j].mtee_question_option_value, question.questionType, index)">
                                                           {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                             </mat-radio-button>
                                    </mat-radio-group>
                  </div>


                  <div *ngIf="question.questionType == 'Range'">
                                    <mat-slider thumbLabel #scaleSize (change)="updateSetting($event, question.mtee_question_id, question.questionType, index)" step="1" min="0" max="30" thumbLabel tickInterval="1" [value]="scaleSize"></mat-slider>
                  </div>

                  <div *ngIf="question.questionType == 'Special1'">
                                <mat-form-field appearance="fill">
                                   <mat-select (selectionChange)="onProvienceSelect($event, question.mtee_question_id, question.questionType, i)" placeholder=""[(value)]="selectedProvience">
                                     <mat-option  *ngFor="let provience of provienceList" [value]="provience">
                                                                          {{provience}}
                                     </mat-option>
                                   </mat-select>
                                </mat-form-field>
                  </div>

                  <div *ngIf="question.questionType == 'Special2'">
                               <mat-form-field appearance="fill">
                                   <mat-select (selectionChange)="onMunicipalitySelect($event, question.mtee_question_id, i)" placeholder=""[(value)]="selectedMunicipality1">
                                     <mat-option  *ngFor="let municipality of municipalityListShow1" [value]="municipality">
                                                                          {{municipality}}
                                     </mat-option>
                                   </mat-select>
                               </mat-form-field>
                  </div>



                  <div *ngIf="question.questionType == 'Checkbox' &&  question.mtee_question_id == 'MS'">

                                <button mat-button type="button" class="mat-radio-button" *ngFor="let option of question.options ;index as k;">
                                      <mat-checkbox *ngIf="k <= 9 &&  question.mtee_question_id == 'MS'" [checked]="question.options[k].checked == true"
                                                    (change)= "answeredQuestionCheckbox(question.mtee_question_id, question.options[k].mtee_question_option_value, $event.checked, index)"
                                                    name="{{question.mtee_question_id}}{{k}}" id="{{question.mtee_question_id}}{{k}}" #checkboxes1>
                                                            {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                      </mat-checkbox>
                                      <mat-checkbox *ngIf="k == 10 &&  question.mtee_question_id == 'MS'" [checked]="question.options[k].checked == true"
                                                    (change)= "answeredQuestionCheckbox1(question.mtee_question_id, question.options[k].mtee_question_option_value, $event.checked, index)"
                                                    name="{{question.mtee_question_id}}{{k}}" id="{{question.mtee_question_id}}{{10}}" #checkboxes1>
                                                            {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                      </mat-checkbox>
                                </button>
                                <!--<pre *ngIf="question.mtee_question_id == 'CBA'">{{ answerModelCheckbox1 | json}}</pre>-->
                  </div>


                  <div *ngIf="question.questionType == 'Checkbox' &&  question.mtee_question_id == 'CBB'">
                              <button mat-button type="button" class="mat-radio-button" *ngFor="let option of question.options ;index as l;">
                                  <mat-checkbox *ngIf="l <= 4 &&  question.mtee_question_id == 'CBB'" [checked]="question.options[l].isChecked == true"
                                                    (change)= "answeredQuestionCheckbox(question.mtee_question_id, question.options[l].mtee_question_option_value, $event.checked, index)"
                                                     name="{{question.mtee_question_id}}{{l}}" id="{{question.mtee_question_id}}{{l}}" #checkboxes2>
                                                            {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                  </mat-checkbox>
                                  <mat-checkbox *ngIf="l == 5 &&  question.mtee_question_id == 'CBB'" [checked]="question.options[l].isChecked == true"
                                                    (change)= "answeredQuestionCheckbox1(question.mtee_question_id, question.options[l].mtee_question_option_value, $event.checked, index)"
                                                    name="{{question.mtee_question_id}}{{l}}" id="{{question.mtee_question_id}}{{5}}" #checkboxes2>
                                                            {{option['mtee_question_option_text'][0][selectedLanguage]}}
                                  </mat-checkbox>
                              </button>
                              <!--<pre *ngIf="question.mtee_question_id == 'CBB'">{{ answerModelCheckbox2 | json}}</pre>-->
                  </div>

                </mat-card>

              </mat-card-content>
              <div *ngIf="last">
                <mat-radio-group>
                  <mat-radio-button [value]="1" (change)="radioChange($event)">{{questionComponentItems.yesCampaign}}</mat-radio-button>
                  <mat-radio-button [value]="0" (change)="radioChange($event)">{{questionComponentItems.noCampaign}}</mat-radio-button>
                </mat-radio-group>

                <form *ngIf="hasCampaignCode" class="col-md-12 col-xs-12" name="saveCampaignForm" role="form" id="saveCampaignForm" [formGroup]="saveCampaignForm">
                    <div class="row jumbotron-fluid">
                      <div class="form-group col-md-12 col-xs-12">
                        {{questionComponentItems.campaignInstructions}}
                        <input type="text" id="mtee_campaign_code" formControlName="mtee_campaign_code" class="form-control"  >
                      </div>
                      <div class="form-group col-md-1 col-xs-12">
                        <button type="submit" class="btn action-button"  [disabled]="!saveCampaignForm.valid" (click)="searchcampaign()"  id="mtee_save_campaign_btn">{{questionComponentItems.verifyButton}}</button>
                      </div>
                    </div>
                </form>
              </div>
              <div>
                <button mat-button mat-raised-button type="button" class ="left" *ngIf="index !== 0" matStepperPrevious (click)="prevStep(index)">{{questionComponentItems.backButton}}</button>
                <button mat-button mat-raised-button type="button" class ="right"  [disabled]="(questionsInPage[index] > questionsAnswered[index]) && (questionsInPage[index] != 15)" *ngIf="!last" matStepperNext (click)="nextStep(index)">{{questionComponentItems.nextButton}}</button>
                <button mat-button mat-raised-button type="button" class ="submit"  [disabled]="(progress != totalQuestions) || (!verifiedCampaign)"  *ngIf="last" (click)="submit()">{{questionComponentItems.submitButton}}</button>
              </div>
            </mat-step>
          </mat-horizontal-stepper>
        </mat-card>
  </div>
</div>

<!--<ng-template #campaignModal let-modal>
<div class="modal-header">
  <h4 class="modal-title" id="campaignModal-title">Yes, I have a campaign Code</h4>
</div>
<div class="modal-body">

                                         <form class="col-md-12 col-xs-12 jumbotron" name="saveCampaignForm" role="form" id="saveCampaignForm" [formGroup]="saveCampaignForm">
                                                       <div class="row jumbotron-fluid">
                                                           <div class="form-group col-md-9 col-xs-12">
                                                              <label for="mtee_campaign_code" >Campaign Code</label>
                                                              <input type="text" id="mtee_campaign_code" formControlName="mtee_campaign_code" class="form-control" placeholder="Enter a unique Campaign Code" >
                                                           </div>
                                                           <div class="form-group col-md-3 col-xs-12">
                                                                <button type="submit" class="btn action-button"  [disabled]="!searchCampaignForm.valid" (click)="searchcampaign()"  id="mtee_save_campaign_btn">Verify Campaign</button>
                                                           </div>
                                                       </div>
                                         </form>
</div>
<div class="modal-footer">
  <button mat-raised-button type="button" class="close left" aria-label="Close" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">×</span>
  </button>
  <button type="button" class="btn btn-outline-dark right" [disabled]="verifiedCampaign"  (click)="modal.dismiss('Cross click')">Save</button>
</div>
</ng-template>-->
