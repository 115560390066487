<div class="container-lg col-md-10">

    <div class="row">
      <div class="col-sm-12">
        <div class="alert alert-dismissible">
          <strong class="">{{feedbackComponentItems.mtee_feedback_tagline }}  &nbsp; {{feedbackComponentItems.mtee_feedback_explanation}} </strong>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-12">
                     
      </div>
    </div>

    <div class="row jumbotron">
<div class="text-center"><a href="https://zef.fi/s/382v2phr/">https://zef.fi/s/382v2phr/</a></div>
      <div class="col-sm-2 p-3" >
       
       
      </div>
      <div class="col-sm-10 text-center">
        <button mat-button [routerLink]="['/logout']" > <mat-icon >close </mat-icon></button>
        
      </div>

   </div>

  </div>
